import React from "react"
import styled from 'styled-components';
import { Link } from "../components/StyledLink";
import Layout from "../components/layout"
import SEO from "../components/seo"

const ThanksContainer = styled.div`
  background: no-repeat center center fixed;
  background-size: cover;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(155, 255, 255, 0.6)), url(https://picsum.photos/1200);
  text-align: center;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  color: rgba(255,255,255,0.9);
`

const ThanksPage = () => (
  <Layout>
    <SEO title="Submission received" />
    <ThanksContainer>
      <h1>Thanks for your submission!</h1>
      <p>Typical response times are under 4 weeks.</p> 
      <p>Feel free to contact us if you haven't heard anything by then.</p>
      <Link to='/'>home</Link>
    </ThanksContainer>
  </Layout>
)

export default ThanksPage
